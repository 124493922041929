/* Put here classes for global use only */

body {
  margin: 0 !important; /* workaround for extra body margin after upgrading antd v4 -> v5 */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.white {
  color: #ffffff !important;
}

.icon-aligned {
  display: flex !important;
  justify-content: center;
}

.icon-aligned-left {
  display: flex !important;
  justify-content: left;
}

.icon-aligned-right {
  display: flex !important;
  justify-content: right;
}

.center {
  text-align: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.right {
  text-align: right;
}

.left {
  text-align: left;
}

.btn-icon {
  padding-right: 8px;
  vertical-align: middle;
}

.btn-icon-r {
  padding-left: 8px;
  vertical-align: middle;
}

.btn-margin-left {
  margin-left: 8px;
}

.btn-margin-right {
  margin-right: 8px;
}

.btn-margin-right-4 {
  margin-right: 4px;
}

.margin-top-24 {
  margin-top: 24px;
}

.ultralight {
  font-weight: 200;
  opacity: 0.6;
}

.quitelight {
  font-weight: 300;
  opacity: 0.8;
}

.ultraheavy {
  font-weight: 700 !important;
}

.smaller {
  font-size: 0.8em !important;
}

.much-smaller {
  font-size: 0.6em !important;
}

.bigger {
  font-size: 1.2em !important;
}

.much-bigger {
  font-size: 1.4em;
}

.much-much-bigger {
  font-size: 2em;
}

.width100percent {
  width: 100%;
}

.hidden {
  display: none;
}

.clickable {
  cursor: pointer;
  opacity: 0.6;
}

.clickable:hover {
  opacity: 1;
}

.clickable1 {
  cursor: pointer;
  opacity: 0.8;
}

.clickable1:hover {
  opacity: 1;
}

.noselect {
  -webkit-user-select: none;
  user-select: none;
}

.invisible {
  width: 0 !important;
  height: 0 !important;
  max-width: 0 !important;
  max-height: 0 !important;
  overflow: hidden;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.disabled-2 {
  pointer-events: none;
  opacity: 0.8;
}

img.bordered {
  border: 1px solid #00ae9e;
  border-radius: 4px;
  margin: 4px 24px 8px 24px;
  text-shadow: 0 0 0 #495057;
  -webkit-box-shadow: 0px 0px 15px -10px #23395d;
  -moz-box-shadow: 0px 0px 15px -10px #23395d;
  box-shadow: 0px 0px 10px -5px #23395d;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-self: center;
}

.flex-horizontal-align-center {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.flex-horizontal-right {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-self: center;
  justify-content: flex-end;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.do-not-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.do-not-wrap-200px {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.do-not-wrap-300px {
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.do-not-wrap-400px {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* Overwrite MDB CSS */

h3 {
  font-size: 1.17em !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

h4 {
  font-size: 1em !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

h5 {
  font-size: 0.83em !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

/* <Corporate colors> */

.corpo-warm-red-bg {
  background-color: #ff4f1f !important;
}

.corpo-blue-bg {
  background-color: #23395d !important;
}

.corpo-teal-bg {
  background-color: #00ae9e !important;
}

.corpo-aqua-bg {
  background-color: #3b90aa !important;
}

.corpo-charcoal-bg {
  background-color: #4e5054 !important;
}

.corpo-medium-gray-bg {
  background-color: #959699 !important;
}

.corpo-light-gray-bg {
  background-color: #f3f3f3 !important;
}

.corpo-warm-red {
  color: #ff4f1f !important;
}

.corpo-blue {
  color: #23395d !important;
}

.corpo-teal {
  color: #00ae9e !important;
}

.corpo-aqua {
  color: #3b90aa !important;
}

.corpo-charcoal {
  color: #4e5054 !important;
}

.corpo-medium-gray {
  color: #959699 !important;
}

.corpo-light-gray {
  color: #f3f3f3 !important;
}

.corpo-warm-red-border {
  border: 1px solid #ff4f1f !important;
}

.corpo-warm-red-border-bottom-1 {
  border-bottom: 1px solid #ff4f1f !important;
}

.corpo-warm-red-border-bottom-2 {
  border-bottom: 2px solid #ff4f1f !important;
}

.corpo-blue-border {
  border: 1px solid #23395d !important;
}

.corpo-teal-border {
  border: 1px solid #00ae9e !important;
}

.corpo-aqua-border {
  border: 1px solid #3b90aa !important;
}

.corpo-charcoal-border {
  border: 1px solid #4e5054 !important;
}

.corpo-medium-gray-border {
  border: 1px solid #959699 !important;
}

.corpo-light-gray-border {
  border: 1px solid #f3f3f3 !important;
}

/* </Corporate colors> */

/* <Corporate fonts> */

@font-face {
  font-family: springwood;
  src: url('./fonts/springwood_brush-webfont.woff');
}

.corpo-font {
  font-family: springwood;
}

.corpo-font-roboto {
  font-family: 'Roboto', sans-serif;
}

/* </Corporate fonts> */

/* <blink> */

.blinking {
  animation: blinking-anim 1s linear infinite;
}

@keyframes blinking-anim {
  50% {
    opacity: 0.4;
  }
}

.blink-1 {
  opacity: 1;
  animation-name: blink-anim-1;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-iteration-count: 2;
  animation-direction: alternate;
  animation-fill-mode: backwards;
}

.blink-2 {
  opacity: 1;
  animation-name: blink-anim-1;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-iteration-count: 10;
  animation-direction: alternate;
  animation-fill-mode: backwards;
}

.blink-3 {
  opacity: 1;
  animation-name: blink-anim-1;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-iteration-count: 5;
  animation-direction: alternate;
  animation-fill-mode: backwards;
}

@keyframes blink-anim-1 {
  50% {
    opacity: 0.6;
  }
}

.move-horizontaly {
  margin-left: -2;
  animation: move-horizontaly-anim 2s linear infinite;
}

@keyframes move-horizontaly-anim {
  50% {
    margin-left: 2px;
  }
}

/* </blink> */
